
if (!window.vimas) {
    var vimas = {};
};

if (!window.vimas.sco) {
    vimas.sco = {};
};

vimas.sco.lms = new function () {

    var context = this;

    // values / events cache
    var _values = {};
    var _events = [];
    var _tracks;

    this.url = {
        progress: '',
        track: '',
        log: ''
    };

    this.vmGetValue = function (keys, lastAttempt) {

        // check if token is present
        // and sco id is a number
        // if not than it's a standalone sco
        // and no values can be read for it
        if (!vimas.sco.lms.token || !context.url.track || isNaN(vimas.sco.lms.sco))
            return $.when([]);

        // first try to read data from cache
        // check if tracks cache is present        
        // check if all required keys
        // are present in cached tracks
        let cached = _tracks ? _tracks.filter(x => x.activity == vimas.sco.lms.sco) : [];
        let isCached = keys.every(key => cached.find(track => track.key == key));

        // request object
        let request;

        // if all values are from cache
        // than just return them
        // otherwise call API
        if (isCached) {

            request = $.when(cached);

        } else {

            // join keys to string param
            var param = keys.join(',');

            request = $.ajax({
                type: 'GET',
                url: context.url.track,
                headers: {
                    'x-vms-session': vimas.sco.lms.session
                },
                data: {
                    token: vimas.sco.lms.token,
                    sco: vimas.sco.lms.sco,
                    param: param
                }
            });
        };

        if (lastAttempt) {

            request = request.then(function (result) {

                // get results from current attempt
                // combine into one object
                return result.filter(x => x.attempt == vimas.sco.lms.attempt)
                    .reduce(function (p, n) {

                        p[n.key] = n.value;
                        return p;

                    }, {});
            });
        };

        return request.fail(function () {

            console.log('Failed to load sco data');
        });
    };

    this.vmGetBestScore = function () {

        return this.vmGetValue(['cmi.core.lesson_status', 'cmi.vms_absolute_score'])
            .then(function (result) {

                return vmGetBestScore(result);
            });
    };

    this.vmSetValue = function (key, value) {

        if (value != null) {

            _values[key] = value;

            // find value from tracks cache
            // and update it
            if (_tracks) {

                var track = _tracks.find(x => x.activity == vimas.sco.lms.sco && x.attempt === vimas.sco.lms.attempt && x.key === key);
                if (track)
                    track.value = value;
            };
        };
    };

    this.vmSaveEvents = function (events) {

        if (!isNaN(vimas.sco.lms.sco)) {

            // add activity id to events
            events = events.map(function (item) {
                item.activityid = vimas.sco.lms.sco;
                return item;
            });

            events.forEach(function (event) {

                _events.push(event);
            });
        };
    };

    this.vmSave = function (score, scoreCoef) {

        context.vmSetValue('cmi.vms_absolute_score', score);
        context.vmSetValue('cmi.core.score.raw', Math.round(score * scoreCoef));
    };

    this.vmCompleted = function () {
        context.vmSetValue('cmi.core.lesson_status', 'completed');
    };

    this.vmIncomplete = function () {
        context.vmSetValue('cmi.core.lesson_status', 'incomplete');
    };

    this.vmSkipped = function () {
        context.vmSetValue('cmi.core.lesson_status', 'skipped');
    };

    this.vmCommit = function () {

        // if no values than nothing to do here
        if (Object.keys(_values) == 0)
            return $.when();

        // check if token is present
        // and sco id is a number
        // if not than it's a standalone sco
        // and no values can be read for it
        if (!vimas.sco.lms.token || !context.url.track || isNaN(vimas.sco.lms.sco)) {

            // clear values
            // and return resolved promise
            _values = {};
            return $.when();
        };

        // prepare data for saving
        var data = [{
            sco: vimas.sco.lms.sco,
            attempt: vimas.sco.lms.attempt,
            data: _values
        }];

        // convert to JSON
        var json = JSON.stringify(data);

        return context.track(json)
                    .done(() => {

                        // clear values cache
                        _values = {};
                    })
                    .fail((err) => {

                        console.log('Failed to save sco data');
                    });
    };

    this.vmCommitEvents = function () {

        return vmSaveEvents();
    }

    function vmSaveData(data) {
        return $.ajax({
            type: 'POST',
            url: context.url.track,
            headers: {
                'x-vms-session': vimas.sco.lms.session
            },
            data: {
                token: vimas.sco.lms.token,
                data: data
            }
        });
    };

    function vmSaveEvents() {

        // if no events than nothing to do here
        if (_events.length == 0)
            return;

        // if log URL is not specified
        // than nothing should be saved
        if (!context.url.log) {
            return;
        }

        var used = _events.filter(x => x.eventid == 'used').reduce((p, n) => p + n.params.time, 0);
        var record = _events.filter(x => x.eventid == 'record').reduce((p, n) => p + n.params.time, 0);
        var model = _events.filter(x => x.eventid == 'play_model').reduce((p, n) => p + n.params.time, 0);

        console.log({ used: used, record: record, model: model });

        return $.ajax({
            type: 'POST',
            url: context.url.log,
            headers: {
                'x-vms-session': vimas.sco.lms.session
            },
            data: {
                token: vimas.sco.lms.token,
                courseidnumber: vimas.sco.lms.courseid,
                events: JSON.stringify(_events)
            }
        }).done(function () {

            // clear events cache
            _events = [];

        }).fail(function () {

            console.log('Failed to save events');
        });
    };

    function vmGetBestScore(result) {

        // score key
        var scoreKey = 'cmi.vms_absolute_score';

        // get scores across all attempts
        var prevScores = result.filter(function (x) {
            return x.key == scoreKey;
        }).map(function (x) {

            var prevScoreCalculated = parseFloat(x.value);

            return !isNaN(prevScoreCalculated) ? prevScoreCalculated : -1;
        });

        // add minimum default value
        prevScores.push(-1);

        // get best score from previous ones
        return Math.max.apply(Math, prevScores);
    };

    function vmProgress() {

        // make a request for progress
        // read progress from cache
        return $.when(_tracks);
    };

    function vmUpdateTracksCache(tracks) {

        _tracks = tracks;
    };

    function vmRandom(current, options) {
        // Shuffle array and select 1st element.
        let elem = options.shuffle()[0]; 
        return $.when({ idnumber: elem.result });
    }

    this.vmGetQuestions = function (current, $xml) {
        const items = $xml.map((ind, element) => $(element).attr('id'))
                        .shuffle()
                        .toArray();
        return $.Deferred().resolve(items);
    }

    function vmFinish() {

        // default behavior for test finish
        // should be overriden by host website
        console.log('Test finished default');
    }

    this.track = vmSaveData;
    this.progress = vmProgress;
    this.tracksCache = vmUpdateTracksCache;
    this.random = vmRandom;
    this.finish = vmFinish;
};