// 
// Web LM player
//
// args.application: application id
// args.token: user token
// args.courseid: course id
// args.config: application configuration
vimas.sco.webplayer = function (args) {

    var self = this;

    var _scos = [];
    var _sco;
    var _current;
    var _last;

    function vmLoad(course, progress, lastPage) {

        if (!course.scos) {

            // trigger the access denied
            $(self).trigger('access_denied');

            return $.when();

        } else {

            vmInitLMS(course.scos, progress);

            course.id = args.courseid;
            course.skin = JSON.parse(course.skin);

            // update activities configuration
            var scos = vmPrepare({
                application: args.application,
                token: args.token,
                session: args.session,
                config: args.config,
                course: course,
                lang: args.lang
            });

            // trigger prepared event
            // last chanse to modify configuration
            // before actual load
            $(self).trigger('prepared', { scos: scos });

            // preload activities
            // from selected course
            var defaults = args.config.sco_defaults;
            var preload = vimas.loader.preload({
                bucket: defaults.bucket_script_url || defaults.bucket_root_url,
                config: defaults.idevice_config_file_name,
                skin: course.skin,
                lang: args.lang,
                scos: scos
            });

            // get first page to open on load depending on
            // course mode
            // activity states
            // last opened page
            var first = vmGetFirstPage(progress, scos, {
                token: args.token,
                course: course
            }, lastPage);

            // save list of scos        
            _scos = scos;

            // when preloading is finished
            // check user progress
            return preload.then(function () {

                return first.then(function (sco) {

                    $(self).trigger('webplayer.start');

                    // load sco
                    vmLoadSco(sco.id);
                });
            });            
        }
    };

    function vmInitLMS(scos, progress) {

        // user / course data
        vimas.sco.lms.token = args.token;
        vimas.sco.lms.session = args.session;
        vimas.sco.lms.courseid = args.courseid;

        // save API's
        vimas.sco.lms.url.progress = args.config.api.progress;
        vimas.sco.lms.url.track = args.config.api.track;
        vimas.sco.lms.url.log = args.config.api.log;

        // navigation methods
        vimas.sco.lms.vmNext = vmNext;
        vimas.sco.lms.vmFinish = vmNext;
        vimas.sco.lms.vmPrevious = vmPrevious;
        vimas.sco.lms.vmExit = vmExit;
        vimas.sco.lms.vmError = vmError;  
        vimas.sco.lms.finish = vmFinish;

        var tracks;
        if (!progress || progress.length == 0) {

            tracks = scos.reduce(function (tracks, sco, index) {

                var params = JSON.parse(sco.params);

                var score = {
                    activity: sco.id,
                    type: params.idevice_type,
                    content: params.title_id,
                    name: params.sco_name,
                    passage_type: params.passage_type,
                    layout: params.layout,
                    order: index,
                    attempt: vimas.sco.lms.attempt || 1,
                    key: 'cmi.vms_absolute_score',
                    value: null
                };

                var status = {
                    activity: sco.id,
                    type: params.idevice_type,
                    content: params.title_id,
                    name: params.sco_name,
                    passage_type: params.passage_type,
                    layout: params.layout || '',
                    order: index,
                    attempt: vimas.sco.lms.attempt || 1,
                    key: 'cmi.core.lesson_status',
                    value: null
                };

                var details = {
                    activity: sco.id,
                    type: params.idevice_type,
                    content: params.title_id,
                    name: params.sco_name,
                    passage_type: params.passage_type,
                    layout: params.layout,
                    order: index,
                    attempt: vimas.sco.lms.attempt || 1,
                    key: 'cmi.vms_quiz_xml',
                    value: null
                };

                tracks.push(score);
                tracks.push(status);
                tracks.push(details);

                return tracks;

            }, []);

        } else {

            tracks = progress.reduce(function (tracks, item) {

                var sco = scos.find(x => x.id == item.id);
                var params = JSON.parse(sco.params);

                var score = {
                    activity: sco.id,
                    type: params.idevice_type,
                    content: params.title_id,
                    name: params.sco_name,
                    passage_type: params.passage_type,
                    layout: params.layout,
                    order: item.order,
                    attempt:  item.attempt,
                    key: 'cmi.vms_absolute_score',
                    value: item.score
                };

                var status = {
                    activity: sco.id,
                    type: params.idevice_type,
                    content: params.title_id,
                    name: params.sco_name,
                    passage_type: params.passage_type,
                    layout: params.layout,
                    order: item.order,
                    attempt: item.attempt,
                    key: 'cmi.core.lesson_status',
                    value: item.status
                };

                var details = {
                    activity: sco.id,
                    type: params.idevice_type,
                    content: params.title_id,
                    name: params.sco_name,
                    passage_type: params.passage_type,
                    layout: params.layout,
                    order: item.order,
                    attempt: item.attempt,
                    key: 'cmi.vms_quiz_xml',
                    value: item.details
                };

                tracks.push(score);
                tracks.push(status);
                tracks.push(details);

                return tracks;

            }, []);
        };

        vimas.sco.lms.tracksCache(tracks);
    };

    // prepare activites configuration
    function vmPrepare(args) {

        var config = $.extend({}, args.config.sco_defaults, args.course.skin);
        config.lang = args.lang;

        // prepare scos array
        // update container and course level parameters
        var scos = args.course.scos.map(function (item) {

            item.container = '#sco_' + item.id;
            item.isactivity = true;

            var params = JSON.parse(item.params);
            params = $.extend({}, config, params);
            params.ss_model = args.course.ss_model;
            params.course_mode = args.course.mode;
            params.props = item.props ? JSON.parse(item.props) : null;

            item.params = params;

            return item;
        });

        // call imlpementation specific 
        // for normal and audio modes
        // preparation functions
        scos = self.prepare(scos, args);

        // set application and user paramters
        scos.map(function (item) {

            item.params.application_id = args.application;
            item.params.user_id = args.token;

            return item;
        });

        return scos;
    };

    // get first page to open
    function vmGetFirstPage(progress, scos, args, lastPage) {

        if (args.course.mode == 'assessment') {

            // find last attempted page
            var last = vmGetLastAttemptedPage(progress, args.course.max_attempts);

            // set current attempt
            vimas.sco.lms.attempt = last.attempt;

            if (last.id < 0) {

                // no page to open
                // return rejected value
                return $.Deferred().reject();

            } else if (!last.id) {

                // if no last page
                // then it's new attempt
                // so open first page
                return $.when(scos[0]);

            } else {

                // if assessment course
                // then open last unused ( current by progress ) page                
                return vmGetNextIncomplete(progress, scos, last.id, last.attempt);
            };

        } else {

            // fro remediation course
            // there will be only 1 attempt
            vimas.sco.lms.attempt = 1;

            // find page by last id
            var last = vmGetLastOpenedPage(scos, lastPage);
            return $.when(last);
        };

        function vmGetLastAttemptedPage(progress, max_attempts) {

            // get max attempt
            var attempts = progress.map(function (x) { return x.attempt; });
            var attempt = attempts.length > 0 ? Math.max.apply(Math, attempts) : 0;

            // check if all items in last attempt is completed
            var completed = progress.filter(x => x.attempt == attempt)
                                .every(vmIsActivityCompleted);

            // if all activities are completed
            // than start new attempt
            if (completed)
                attempt++;

            // don't allow attempt
            // over maximum value
            if (max_attempts && attempt > max_attempts) {

                // trigger the no atempts event
                $(self).trigger('no_attempts', max_attempts);

                return {
                    attempt: max_attempts,
                    id: -1
                };

            } else if (completed) {

                return {
                    attempt: attempt,
                    id: null
                };

            } else {

                // inside max attempt
                // find activity
                // which belongs to latest attempt
                // and is not "completed"
                var last = progress.filter(x => x.attempt == attempt)
                                .filter(x => x.timemodified)
                                .sort((a, b) => b.timemodified - a.timemodified)
                                .shift();

                return {
                    attempt: attempt,
                    id: last ? last.id : null
                };
            };
        };

        function vmGetLastOpenedPage(scos, lastPage) {

            // try to get sco from list                                    
            var sco = scos.find(x => x.id == lastPage);

            // if not found or finish activity
            // than open first page
            sco = self.lastUsePage(sco, scos);

            return sco;
        };

        function vmGetNextIncomplete(progress, scos, id, attempt) {

            var sco = scos.find(x => x.id == id);
            var scoProgress = progress.find(x => x.id == id && x.attempt == attempt);

            if (vmIsActivityCompleted(scoProgress)) {

                return vmNextCalculate(sco, scos)
                            .then((item) => item ? vmGetNextIncomplete(progress, scos, item.id, attempt) : sco);

            } else {

                return $.when(sco);
            };
        };
    };

    // is activity incomplete
    function vmIsActivityCompleted(activity) {

        return !!activity && ( activity.status == 'completed' || activity.status == 'passed' || activity.status == 'skipped' );
    };

    // load sco by id
    function vmLoadSco(id) {

        // get sco by id
        var sco = _scos.first('id', id);

        // if not found
        // than open first page
        if (!sco)
            sco = _scos[0];

        // set current sco id
        vimas.sco.lms.sco = sco.id;                

        if (sco.activity) {
           
            // if activity already initialized
            // than just start it
            sco.activity.run();

            $(self).trigger('loaded', sco);

        } else {

            // if no activity
            // than create one
            sco.activity = Idevice.postinit('sco_' + sco.id, sco.params);

            $(sco.activity).on('loaded', function (e) {

                $(self).trigger('loaded', sco);
            });
        };
        
        // set activity as selected
        _current = sco.id;
        _sco = sco;
       
        $(self).trigger('current', sco);

        // save last used activity
        if (sco.isactivity)
            _last = sco.id;

        self.last = _last;

    };

    function vmExit(dest) {

        $(self).trigger('webplayer.exit', dest);
    };

    function vmFinish() {

        $(self).trigger('webplayer.finish');
    }

    function vmError(type) {

        $(self).trigger('webplayer.error', type);
    }

    function vmOpen(page) {

        if (page)
            vmLoadSco(page);
    };

    function vmNextCalculate(current, scos) {

        let props = current.params.props;

        if (props && props.p_adaptive_schematic && props.p_adaptive_schematic.conditions) {

            return vimas.sco.lms.progress()
                        .then(function (progress) {

                            let conditions = props.p_adaptive_schematic.conditions;
                            let condition = conditions.filter((condition) => 

                                condition.operation == '>' ||
                                condition.operation == '>=' ||
                                condition.operation == '<' ||
                                condition.operation == '<='

                            ).find(function (condition) {

                                // calculate sum of scores
                                // for activities
                                var sum = condition.scoIds.reduce(function (sum, idnumber) {

                                    // find sco by idnumber
                                    // find score for current page in current attempt
                                    var sco = scos.find(x => x.idnumber == idnumber);
                                    var score = progress.find(x => x.activity == sco.id && x.key == 'cmi.vms_absolute_score' && x.attempt == vimas.sco.lms.attempt);

                                    return sum + parseInt(score.value || 0);

                                }, 0);

                                // check conditions
                                // depending on operation specified
                                if (condition.operation == '>' && sum > parseInt(condition.controlValue)) {

                                    return true;

                                } else if (condition.operation == '>=' && sum >= parseInt(condition.controlValue)) {

                                    return true;

                                } else if (condition.operation == '<' && sum < parseInt(condition.controlValue)) {

                                    return true;

                                } else if (condition.operation == '<=' && sum <= parseInt(condition.controlValue)) {

                                    return true;

                                } else {

                                    return false;
                                }                            
                            });

                            // find all next and random conditions
                            let random = conditions.filter((x) => x.operation == 'rnd');
                            let next = conditions.find((x) => x.operation == 'nxt');

                            // if condition was met than use it
                            // else select one of the random
                            // else move by next condition
                            if (condition) {

                                return condition.result;

                            } else if (random.length > 0) {

                                // try to find activity
                                // which was already completed
                                // among the random set
                                let previous = random.map((item) => {

                                                        let sco = scos.find(x => x.idnumber == item.result);
                                                        let status = progress.find((x) => x.activity == sco.id && x.key == 'cmi.core.lesson_status' && x.attempt == vimas.sco.lms.attempt);
                                                        item.status = status ? status.value : null;
                                                        return item.result;
                                                     })
                                                     .find((x) => x.status == 'completed');

                                // if previously completed activity
                                // is found than select it again
                                // else select random activity
                                if (!!previous) {

                                    return previous.result;

                                } else {

                                    // use API to get random path
                                    // according to exposure rules
                                    return vimas.sco.lms.random(current.idnumber, random)
                                                .then((x) => x.idnumber)
                                };

                            } else if (next) {

                                return next.result;
                            };
                        })
                        .then((idnumber) => {
                          
                            // if one of the conditions met
                            // than navigate to specified page
                            // otherwise just move to the next page
                            if (idnumber)
                                return scos.find(x => x.idnumber == idnumber);
                            else
                                return vmNextSequence(current, scos);
                        })

        } else {

            var next = vmNextSequence(current, scos);
            return $.when(next);
        };
    };

    function vmNextSequence(current, scos) {

        var ind = scos.findIndex(x => x.id == current.id);

        var sco = scos[ind + 1];
        if (sco && sco.isactivity)
            return sco;
        else
            return null;
    };

    function vmNext() {

        var current = _scos.find(x => x.id == _current);

        vmNextCalculate(current, _scos)
            .then(function (next) {

                if (next)
                    vmOpen(next.id);
                else
                    $(self).trigger('no_actitity_already');
            });
    };

    function vmPrevious() {

        var ind = _scos.findIndex(x => x.id == _current);

        var sco = _scos[ind - 1];
        if (sco && sco.isactivity)
            vmOpen(sco.id);
    };

    this.load = vmLoad;
    this.open = vmOpen;
    this.next = vmNext;
    this.previous = vmPrevious;
    this.exit = vmExit;
};

vimas.sco.webcourse = function (args) {

    var self = this;
    var player = new vimas.sco.webplayer(args);
    var current = null;

    player.prepare = vmPrepare;
    player.lastUsePage = vmLastUsePage;

    self.load = player.load;
    self.exit = player.exit;
    self.open = player.open;
    self.next = player.next;
    self.previous = player.previous;
    self.stop = vmStop;

    $(player).on('prepared', function (e, data) {

        $(self).trigger('prepared', data);
    });

    $(player).on('current', function (e, sco) {

        current = sco;
        $(self).trigger('current', sco);
    });

    $(player).on('no_actitity_already', function (e) {

        $(self).trigger('no_actitity_already');
    });

    $(player).on('access_denied', function (e) {

        $(self).trigger('access_denied');
    });

    $(player).on('no_attempts', function (e, data) {

        $(self).trigger('no_attempts');
    });

    $(player).on('webplayer.start', function (e, data) {

        $(self).trigger('webcourse.start');
    });

    $(player).on('webplayer.error', function (e, data) {

        $(self).trigger('webcourse.error', data);
    });

    $(player).on('webplayer.finish', function (e, data) {

        $(self).trigger('webcourse.finish');
    });

    $(player).on('webplayer.exit', function (e, dest) {

        switch (dest) {

            case 'miccheck':

                player.open(dest);

                $(self).trigger('webcourse.exit', dest);

                break;

            case 'page':

                player.open(player.last);

                break;

            default:

                player.open(null);

                $(self).trigger('webcourse.exit', dest);

                break;
        };
    });

    // create mic check param set
    function vmMicCheckParams(config, course_mode, model) {

        var param = $.extend({}, config);

        // set default params for microphone check
        param.idevice_type = 'MCCA';
        param.title_id = 'G00000';
        param.default_difficulty_level_file = 'miccheck.xml';
        param.default_difficulty_level_index = '1';
        param.recoring_time_limit = '00:10';
        param.ss_model = model;
        param.course_mode = course_mode;
        param.layout = 'standalone';

        return param;
    };

    // update default preload settings
    // specially for web player
    function vmPrepare(scos, args) {

        var config = $.extend({}, args.config.sco_defaults, args.course.skin);
        config.lang = args.lang;

        var scos = scos.concat({
            id: 'miccheck',
            container: '#sco_miccheck',
            type: 'MCCA',
            isactivity: false,
            params: vmMicCheckParams(config, args.course.mode, args.course.ss_model)
        });

        return scos;
    }

    //handle opening of course
    //when the course was completely passed earlier
    function vmLastUsePage(sco, scos) {

        if (!sco || sco.type == 'FC')
            sco = scos[0];

        return sco;
    };

    // stop current activity
    function vmStop() {

        //stop current activity
        current.activity.stop();
    };
}